// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
import 'bootstrap';

//require('bootstrap-modal')
import "@fortawesome/fontawesome-pro/js/all";

import $ from 'jquery';
global.$ = jQuery;
//import dt from 'datatables.net-bs4'
//$.fn.DataTable = dt
import  "jquery-validation"

//require( 'datatables.net' );
require( 'datatables.net-bs4' );
require( 'datatables.net-scroller-bs4' );
//require( 'datatables.net-dt' );
require( 'datatables.net-buttons-bs4' );
//require( 'datatables.net-buttons/js/buttons.print.js' )();

require("datatables.net-bs4/css/dataTables.bootstrap4.min.css")
require('packs/custom');



import "../stylesheets/application.scss";
import "../custom/jquery-steps/jquery.steps.min";

const dataTables = [];
document.addEventListener("turbolinks:load", () => {
  if (dataTables.length === 0 && $('.data-table').length !== 0) {
    $('.data-table').each((_, element) => {
      dataTables.push($(element).DataTable({
        pageLength: 50
      }));
    });
  }
});




document.addEventListener("turbolinks:before-cache", () => {
  while (dataTables.length !== 0) {
    dataTables.pop().destroy();
  }
});

// src/application.js
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
const application = Application.start()
const context = require.context("controllers", true, /\.js$/)
application.load(definitionsFromContext(context))


document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
  
})
//import "./custom";
//import  "./calc_units.js";
//import 'packs/calc_units';


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
