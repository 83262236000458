//custom.js
$(function() {
  $('[data-tooltip="tooltip"]').tooltip();
  $('[data-toggle="tooltip"]').tooltip();
});

$(function() {
  $('[data-toggle="popover"]').popover();  
});

$( function(){
  $('[data-toggle="show_field"]').on('click', function(evt){        
    console.log("toggling field");
   if ($('#toggle_show').disabled){
    $('#toggle_show').removeAttr('disabled');
   }else{
    $('#toggle_show').attr('disabled', 'true');
   }     
})
});